import request from "@/utils/request";

// 商品列表
export const shopsListsApi = data => request.post("/api/shops/lists", data);
// 首页推荐商品
export const tuijianShopApi = type => request.post(`/api/shops/tuijianShop?type=${type}`);
// 商品详情
export const shopsShopApi = data => request.post("/api/shops/shop", data);
// 商品列表顶部获取额度接口
export const availableCreditApi = data => request.post("/api/shops/AvailableCredit", data);
// 检验绑卡情况
export const authListApi = () => request.post("/api/auth_list/index");
// 订单接口信息
export const remittanceApi = () => request.post("/api/neworders/remittance");
// 获取收货地址
export const findAddress = data => request.post("/api/shops/findAddress", data);
// 添加收货地址
export const addaddressApi = data => request.post("/api/shops/addaddress", data);
// 添加收货地址
export const editAddressApi = (id, data) => request.post(`/api/shops/editaddress?id=${id}`, data);
// 获取客服信息
export const allocationApi = data => request.post("/api/configs/allocation", data);
// 状态列表
export const ordersListApi = data => request.post("/api/neworders/lists", data);
// 取消深审核
export const cancelOrderApi = data => request.post("/api/neworders/cancelorder", data);
// 确认下单
export const ordersIndexApi = data => request.post("/api/neworders/index", data);
// 去签约
export const productConfirmationLetterApi = data => request.post("/api/face/product_confirmation_letter", data);
// 去签约合同
export const faceIndexApi = data => request.post("/api/face/index", data);
// 享用中查看
export const orderdetailsApi = data => request.post("/api/neworders/orderdetails", data);
// 身份证验证(包含正反面)
export const realNameApi = data => request.post("/api/index/RealName", data);
// 人脸照片
export const faceuploadApi = data => request.post("/api/index/Faceupload", data);
// 实名认证提交
export const realSubmitApi = data => request.post("/api/index/realSubmit", data);
// 绑定联系人
export const contactApi = data => request.post("/api/auth_list/contact", data);
